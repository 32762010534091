import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import CloseIcon from "@material-ui/icons/Close";
import "./Person.scss";
import { useParams, Link } from "react-router-dom";
import { getRouteKeys } from "../../i18n/route-utils";
import { GET_PERSON, GET_SHOWS_BY_PERSON } from "../../queries";
import Loader from "../atoms/loader/Loader";
import {
  filterActiveShows,
  filterShowsForCrew,
  showActive,
  sortShowsByDateReverse,
} from "../../utilities";
import NotFound from "../atoms/NotFound/NotFound";
import SeoTags from "../atoms/seoTags/SeoTags";
import { deadPeople } from "../people/People";

const Person = ({ backLink }) => {
  const { locale } = useIntl();
  const { person } = useParams();
  const [shows, setShows] = useState([]);
  const [personId, setPersonId] = useState("");

  const {
    data: pdata,
    error: pError,
    loading,
  } = useQuery(GET_PERSON, {
    variables: { id: person },
    onCompleted: (data) => {
      if (data.person && data.person.person_import.personId) {
        setPersonId(String(data.person.person_import.personId));
      } else {
        setPersonId("no_id");
      }
    },
  });

  // const { error: showError, loading: showsLoading } = useQuery(
  //   GET_SHOWS_BY_PERSON,
  //   {
  //     variables: { id: personId },
  //     onCompleted: (data) => {
  //       console.log(data);
  //       setShows(filterActiveShows(data.shows.nodes));
  //     },
  //     skip: !personId || personId === 0,
  //   }
  // );

  const [GetShowsByPerson, { error: showError, loading: showsLoading }] =
    useLazyQuery(GET_SHOWS_BY_PERSON, {
      variables: { id: personId },
      onCompleted: (data) => {
        if (personId && personId !== 0 && personId !== "no_id")
          filterShows(data);
      },
      skip: !personId || personId === 0 || personId === "no_id",
    });

  const filterShows = (data) => {
    let newShows,
      newSingleEvents = [];

    let linkedProds = pdata.person.personDetails.linkedProductions || [];
    newShows = linkedProds
      ? [
          ...linkedProds.filter(
            (x) => x.__typename === "Show" && x.status === "publish"
          ),
          ...data.shows.nodes,
        ]
      : data.shows.nodes;

    if (newShows.length > 1) {
      newShows = newShows.filter(filterShowsForCrew);
      newShows.sort(sortShowsByDateReverse);
    }

    newSingleEvents = linkedProds
      ? [
          ...linkedProds.filter(
            (x) => x.__typename === "SingleEvent" && x.status === "publish"
          ),
          ...data.singleEvents.nodes,
        ]
      : data.singleEvents.nodes;

    //TODO: get this to sort the shows by date
    let allShows = filterActiveShows([...newShows, ...newSingleEvents]);

    const arrUniq = [...new Map(allShows.map((v) => [v.title, v])).values()];
    setShows(arrUniq);
  };

  useEffect(() => {
    if (personId && personId !== 0 && personId !== "no_id") {
      GetShowsByPerson();
    } else {
      setShows([]);
    }
  }, [personId, GetShowsByPerson, person]);

  if (loading || showsLoading) {
    return (
      <div className="Person">
        <Loader />
      </div>
    );
  } else if (pError) {
    return (
      <div className="Person">
        <NotFound />
      </div>
    );
  }

  const getLinkRoute = (show) => {
    let baseRoute = `/${locale}/`;

    if (show.__typename === "Show") {
      let routeKey =
        show.productionDetails && show.productionDetails.isSeries
          ? "series"
          : "repertoire";
      baseRoute = `${baseRoute}${getRouteKeys("en", routeKey)[locale]}/${
        show.slug
      }`;
    } else {
      baseRoute = `${baseRoute}${getRouteKeys("en", "events")[locale]}/${
        show.slug
      }`;
    }

    return baseRoute;
  };

  return personId && pdata && pdata.person ? (
    <>
      <SeoTags title={pdata.person.title} seo={pdata.person.seo} />
      <main className="Person">
        <h1>
          {pdata.person.title}{" "}
          {deadPeople.includes(pdata.person.title) && <>†</>}
        </h1>
        {pdata.person.personDetails.jobDescription && (
          <p className="description">
            {parse(
              locale === "en" && pdata.person.personDetails.jobDescriptionEn
                ? pdata.person.personDetails.jobDescriptionEn
                : pdata.person.personDetails.jobDescription
            )}
          </p>
        )}
        {shows && !pdata.person.personDetails.noProductions && (
          <section className="linkedShows">
            {shows.map((show) =>
              showActive(show) ? (
                <Link
                  to={getLinkRoute(show)}
                  key={`${show.slug}-${pdata.person.title}`}
                >
                  &gt;&nbsp;{show.title}
                </Link>
              ) : (
                <div>&gt;&nbsp;{show.title}</div>
              )
            )}
          </section>
        )}
        {backLink && (
          <div className="back">
            <Link to={backLink}>
              &gt;&nbsp;
              <FormattedMessage id="general.back" defaultMessage="zurück" />
            </Link>
          </div>
        )}
      </main>
    </>
  ) : (
    <div className="Person">
      {backLink && (
        <>
          {pError && <NotFound />}
          <Link to={backLink} className="back">
            <CloseIcon />
          </Link>
        </>
      )}
    </div>
  );
};

Person.propTypes = {
  backLink: PropTypes.string,
};

export default Person;

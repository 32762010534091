import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import {
  GET_EVENTS_OF_SHOW,
  GET_EVENTS_OF_VENUE,
  GET_EVENTS_BY_GENRE,
  SEARCH_EVENTS,
  SEARCH_SHOWS_WITH_PREMIERES,
  GET_TIME_FRAME,
  GET_EVENTS_BETWEEN,
} from "../../queries";
import LepoItem from "../leporello/LepoRow/LepoItem";
import DateField from "../atoms/dateField/DateField";
import "./EventCalendar.scss";
import {
  getVenueClass,
  ignoreSpecial,
  specialProductionRoutes,
  venues,
} from "../../i18n/route-utils";
import {
  eventCancelledThea,
  eventIsInPast,
  filterActiveShows,
  showActive,
  sortEventsByDate,
  sortEventsByDateReverse,
  zeroPad,
} from "../../utilities";
import moment from "moment";
import Loader from "../atoms/loader/Loader";
import { Button } from "reakit";
import { useIntl } from "react-intl";

const sortData = (data, withArchive, withoutVenue, endDate) => {
  if (data) {
    if (withArchive) {
      let filtered = data.filter(
        (x) =>
          eventIsInPast(x) &&
          !eventCancelledThea(x) &&
          !(x.event_cancellation && x.event_cancellation.cancelled) &&
          x.eventDetails.stageId !== withoutVenue
      );
      return filtered;
    } else {
      return data.filter(
        (x) =>
          !eventIsInPast(x) &&
          !eventCancelledThea(x) &&
          !(x.event_cancellation && x.event_cancellation.cancelled) &&
          x.eventDetails.stageId !== withoutVenue &&
          (x.eventDetails.filterableDate <= endDate || showActive(x))
      );
    }
  }
};

const EventCalendar = ({
  productionId,
  venueId,
  venueIds,
  search,
  sortInto,
  data,
  type,
  startDate,
  endDate,
  withArchive,
  withPremieres,
  omitVenueInArchive,
  withSeparator,
}) => {
  const [eventData, setEventData] = useState(null);
  const [pageInfo, setPageInfo] = useState();
  const [loadingMore, setLoadingMore] = useState(false);

  const { data: timeFrame } = useQuery(GET_TIME_FRAME);
  const { formatMessage } = useIntl();
  const now = moment().format("yyyyMMDD");

  useEffect(() => {
    if (data && timeFrame) {
      const newData = sortData(
        data,
        withArchive || withPremieres,
        null,
        `${timeFrame.timeFrame.end.year}${zeroPad(
          timeFrame.timeFrame.end.month,
          2
        )}31`
      );
      setEventData(newData);
    }
  }, [data, withArchive, withPremieres, timeFrame]);

  const loadNum = 5;

  const rerouteTo = !!(
    search && specialProductionRoutes.indexOf(search.toLowerCase()) > -1
  )
    ? search.toLowerCase()
    : null;

  const startEnd = useMemo(() => {
    if (timeFrame && !startDate && !endDate) {
      const start = moment(timeFrame.timeFrame.start.month)
        .startOf("month")
        .format("yyyyMMDD");
      const end = "21000101";

      return {
        start: withArchive ? start : now,
        end: withArchive ? now : end,
      };
    } else if (startDate && endDate) {
      return {
        start: startDate,
        end: endDate,
      };
    }
    return null;
  }, [now, timeFrame, withArchive, startDate, endDate]);

  const standardVars = {
    first: withArchive ? loadNum : 300,
    after: null,
  };

  const venueIdValues = useCallback(() => {
    if (venueIds) {
      const splitValues = venueIds.split(",");
      const first = splitValues[0];
      const last = splitValues[splitValues.length - 1];
      return `${first}, ${last}`;
    }
  }, [venueIds]);

  const { loading, fetchMore } = useQuery(GET_EVENTS_OF_SHOW, {
    variables: {
      productionId: String(productionId),
      ...startEnd,
    },
    onCompleted: (data) => {
      saveData(data);
    },
    skip: type !== "byShow" || !startEnd,
  });

  const { loading: venueLoading, fetchMore: fetchMoreByVenue } = useQuery(
    GET_EVENTS_OF_VENUE,
    {
      variables: {
        venueId: venueIds ? venueIdValues() : `${venueId},${venueId}`,
        ...standardVars,
        ...startEnd,
        search,
      },
      onCompleted: (data) => {
        saveData(data);
      },
      skip: type !== "byVenue" || !startEnd,
    }
  );

  const { loading: genreLoading, fetchMore: fetchMoreByGenre } = useQuery(
    GET_EVENTS_BY_GENRE,
    {
      variables: {
        tag: sortInto,
        // omitVenue: omitVenueInArchive || null,
        ...standardVars,
        ...startEnd,
      },
      onCompleted: (data) => {
        saveData(data);
      },
      skip: type !== "byGenre" || !startEnd,
    }
  );

  const { loading: searchLoading, fetchMore: fetchMoreBySearch } = useQuery(
    SEARCH_EVENTS,
    {
      variables: {
        search,
        // omitVenue: omitVenueInArchive || null,
        ...standardVars,
        ...startEnd,
      },
      onCompleted: (data) => {
        saveData(data);
      },
      skip: type !== "bySearch" || withPremieres || !startEnd,
    }
  );

  const { loading: searchPremieresLoading } = useQuery(
    SEARCH_SHOWS_WITH_PREMIERES,
    {
      variables: { search },
      onCompleted: (data) => {
        const newData = [];
        for (const node of filterActiveShows(data.shows.nodes)) {
          if (node.productionInfo.premiereEvent) {
            newData.push(node.productionInfo.premiereEvent);
          }
        }
        setEventData(newData);
      },
      skip: !(type === "bySearch" && withPremieres) || !startEnd,
    }
  );

  const { loading: eventsByDateLoading, fetchMore: fetchMoreByDate } = useQuery(
    GET_EVENTS_BETWEEN,
    {
      variables: {
        ...standardVars,
        ...startEnd,
      },
      onCompleted: (data) => {
        saveData(data);
      },
      skip: type !== "byDate" || !startDate || !endDate,
    }
  );

  const saveData = (data) => {
    if (data) {
      if (pageInfo) setPageInfo(data.events.pageInfo);

      let filteredNodes = sortData(
        data.events.nodes,
        withArchive || withPremieres,
        omitVenueInArchive,
        `${timeFrame.timeFrame.end.year}${zeroPad(
          timeFrame.timeFrame.end.month,
          2
        )}31`
      );

      if (venueIds) {
        const venueIdArray = venueIds.split(",");

        if (venueIdArray.length > 2) {
          filteredNodes = filteredNodes.filter((x) =>
            venueIdArray.includes(String(x.eventDetails.stageId))
          );
        }
      }

      setEventData(filteredNodes);
    }
  };

  const loadMoreArchive = () => {
    let fetchMoreFunction;

    switch (type) {
      case "byShow":
        fetchMoreFunction = fetchMore;
        break;
      case "byVenue":
        fetchMoreFunction = fetchMoreByVenue;
        break;
      case "byGenre":
        fetchMoreFunction = fetchMoreByGenre;
        break;
      case "bySearch":
        fetchMoreFunction = fetchMoreBySearch;
        break;
      case "byDate":
        fetchMoreFunction = fetchMoreByDate;
        break;
      default:
        break;
    }

    if (fetchMoreFunction && pageInfo && pageInfo.hasNextPage) {
      setLoadingMore(true);

      fetchMoreFunction({
        variables: { after: pageInfo.endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setPageInfo(fetchMoreResult.events.pageInfo);

          let sortedMore = sortData(
            fetchMoreResult.events.nodes,
            withArchive || withPremieres
          );
          let newEvents = [...eventData, ...sortedMore];
          setEventData(newEvents);

          setLoadingMore(false);
        },
      });
    }
  };

  if (
    loading ||
    venueLoading ||
    searchLoading ||
    searchPremieresLoading ||
    genreLoading ||
    eventsByDateLoading
  ) {
    return <Loader />;
  }

  if (eventData && eventData.length > 0) {
    return (
      <>
        {withSeparator && (
          <div className="separator archive" role="separator"></div>
        )}
        <section className="calendar">
          <div
            className={`EventCalendar ${getVenueClass(venueId)}`}
            key={`${productionId}${venueId}${search}`}
            role="grid"
            aria-label={formatMessage({
              id: "anchor.events",
              defaultMessage: "Events",
            })}
          >
            {[...eventData]
              .sort(
                withArchive || withPremieres
                  ? sortEventsByDateReverse
                  : sortEventsByDate
              )
              .map(
                (event) =>
                  venues[event.eventDetails.stageId] && (
                    <div
                      key={`${event.eventDetails.startTime}-${event.eventDetails.stageId}`}
                      role="row"
                      className={`row ${
                        !event.eventDetails.customInfo ||
                        ignoreSpecial.includes(event.eventDetails.customInfo)
                          ? ""
                          : "special"
                      } ${
                        withArchive || withPremieres
                          ? "grey"
                          : getVenueClass(event.eventDetails.stageId)
                      } ${venueId === "festival" ? "festival" : ""}`}
                    >
                      <DateField
                        date={event.eventDetails.startTime}
                        archive={withArchive || withPremieres}
                      />
                      <div className="itemContainer" role="cell">
                        <LepoItem
                          data={event}
                          mainVenueId={venueId}
                          linkRoute={rerouteTo}
                        />
                      </div>
                    </div>
                  )
              )}
            {withArchive &&
              pageInfo &&
              pageInfo.hasNextPage &&
              (loadingMore ? (
                <Loader />
              ) : (
                <Button as="div" className="loadMore" onClick={loadMoreArchive}>
                  &gt; Mehr...
                </Button>
              ))}
            {/* <Button as="div" className="loadMore" onClick={loadMoreArchive}>
            &gt; Mehr...
          </Button> */}
          </div>
        </section>
      </>
    );
  } else {
    return null;
  }
};

EventCalendar.propTypes = {
  productionId: PropTypes.string,
  type: PropTypes.string.isRequired,
  venueId: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
  search: PropTypes.string,
  data: PropTypes.array,
};

export default EventCalendar;

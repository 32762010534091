import React, { useEffect, useMemo, useRef, useState } from "react";
import { availableLanguages } from "./i18n/IntlWrapper/IntlWrapper";
import "intl-pluralrules";
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import LanguageRoutes from "./i18n/components/LanguageRoutes";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PageHeader from "./components/pageHeader/PageHeader";
import { useApolloClient, useQuery } from "@apollo/client";
import { animationActive, hidePage1, timeFrame } from "./cache";
import {
  GET_ANIMATION_ACTIVE,
  GET_EVENTS_BETWEEN,
  GET_HEADLINE,
  GET_PAGE_BY_TITLE,
  GET_SETTINGS,
} from "./queries";
import "./App.scss";
import "./styles/fonts.css";
import CookieBanner from "./components/cookieBanner/CookieBanner";
import moment from "moment";
import { useResizer } from "./customHooks";
import Loader from "./components/atoms/loader/Loader";
import IntroAnimation from "./components/atoms/introAnimation/IntroAnimation";
import { useRouteMatch } from "react-router-dom";

const App = () => {
  const { locale } = useIntl();
  const scrollRef = useRef();
  const isMobile = useResizer();
  // const [headerHeight, setHeaderHeight] = useState();
  const client = useApolloClient();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  //preload page 1 data
  client.query({
    query: GET_PAGE_BY_TITLE,
    variables: { title: "Seite 1" },
  });

  const { data: headline } = useQuery(GET_HEADLINE);
  const { data: animationInfo } = useQuery(GET_ANIMATION_ACTIVE);

  const { data: settings } = useQuery(GET_SETTINGS, {
    onCompleted: (data) => {
      let now = moment();
      let y;
      let m;

      const end =
        process.env.REACT_APP_ENV === "DEV"
          ? data.vbSetting.vb_custom_settings.endmonthDev
          : data.vbSetting.vb_custom_settings.endmonth;

      if (now.isBefore(data.vbSetting.vb_custom_settings.startmonth)) {
        y = moment(data.vbSetting.vb_custom_settings.startmonth).year();
        m = moment(data.vbSetting.vb_custom_settings.startmonth).month() + 1;
      } else {
        y = now.year();
        m = now.month() + 1;
      }

      let ey = moment(end).year();
      let em = moment(end).month() + 1;

      setYear(y);
      setMonth(m);

      timeFrame({ start: { year: y, month: m }, end: { year: ey, month: em } });
      hidePage1(data.vbSetting.vb_custom_settings.hidePage1);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const scrollPadding = useMemo(() => {
    if (scrollRef && scrollRef.current) {
      const { offsetWidth, clientWidth } = scrollRef.current;
      const mR = (clientWidth - offsetWidth) * -1;

      const styles = {
        marginRight: mR + "px",
      };
      return styles;
    }
  }, [scrollRef]);

  // useEffect(() => {
  //   if (year && month) {
  //     client.query({
  //       query: GET_EVENTS_BETWEEN,
  //       variables: {
  //         start: `${year}${month}00`,
  //         end: `${year}${month}31`,
  //       },
  //     });
  //   }
  // }, [year, month]);

  return (
    <HashRouter>
      <div className="App">
        <Helmet
          htmlAttributes={{ lang: locale }}
          titleTemplate="%s | Volksbühne am Rosa-Luxemburg-Platz"
          defaultTitle="Volksbühne am Rosa-Luxemburg-Platz"
        >
          {settings && (
            <meta
              name="description"
              content={
                locale === "en"
                  ? settings.vbSetting.seo.seoDescriptionEn ||
                    "Website of Volksbühne am Rosa-Luxemburg-Platz"
                  : settings.vbSetting.seo.seoDescription ||
                    "Webseite der Volksbühne am Rosa-Luxemburg-Platz"
              }
            />
          )}
          {settings && (
            <meta
              name="keywords"
              content={
                locale === "en"
                  ? settings.vbSetting.seo.seoKeywordsEn ||
                    "Volksbühne,René Pollesch,Rosa-Luxemburg-Platz,Theatre"
                  : settings.vbSetting.seo.seoKeywords ||
                    "Volksbühne,René Pollesch,Rosa-Luxemburg-Platz,Theater"
              }
            />
          )}
        </Helmet>
        <Header />
        {headline.headline &&
          headline.headline === "*programme" &&
          !isMobile && (
            <PageHeader
              // setHeaderHeight={setHeaderHeight}
              headline={headline.headline}
            />
          )}

        {/* <div id="scrollable" ref={scrollRef} onScroll={scrollHandler}> */}
        {settings ? (
          <div id="scrollable" ref={scrollRef} style={scrollPadding}>
            {/* <div> */}
            {headline.headline &&
              headline.headline !== "*programme" &&
              !isMobile && (
                <PageHeader
                  // setHeaderHeight={setHeaderHeight}
                  headline={headline.headline}
                />
              )}
            <div id="content">
              {/* <ScrollToTop /> */}
              <Switch>
                <Route exact path="/">
                  <Redirect
                    to={`/${
                      availableLanguages.includes(locale) ? locale : "en"
                    }`}
                  />
                </Route>
                {availableLanguages.map((lang) => (
                  <Route key={`default-${lang}`} path={`/${lang}`}>
                    <LanguageRoutes
                      lang={lang}
                      scrollableDiv={scrollRef}
                      startpage={
                        settings.vbSetting.vb_custom_settings.startseite
                      }
                      hidePage1={
                        settings.vbSetting.vb_custom_settings.hidePage1
                      }
                    />
                  </Route>
                ))}
                <Route exact path="/jobs">
                  <Redirect
                    to={`/${
                      availableLanguages.includes(locale) ? locale : "en"
                    }/jobs`}
                  />
                </Route>
                <Route path="*">
                  <Redirect
                    to={`/${
                      availableLanguages.includes(locale) ? locale : "en"
                    }/404`}
                  />
                </Route>
              </Switch>
              <Footer
                hideAktuelles={
                  settings.vbSetting.vb_custom_settings.hideAktuelles
                }
              />
            </div>
            {/* </div> */}
          </div>
        ) : (
          <Loader />
        )}
        {animationInfo.animationActive && <IntroAnimation />}
        <CookieBanner />
      </div>
    </HashRouter>
  );
};

export default App;
// export default React.memo(App);

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Link, useParams, useRouteMatch, Redirect } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import "./PressSection.scss";
import { headline } from "../../cache";
import parse from "html-react-parser";
import { GET_SETTINGS, GET_SHOW_WITH_PRESS } from "../../queries";
import Loader from "../atoms/loader/Loader";
import { saveAs } from "file-saver";
import ProductionTitle from "../atoms/ProductionTitle/ProductionTitle";
import { Button } from "reakit";

const PressSection = ({ generalInfo }) => {
  const { formatMessage, locale } = useIntl();
  const { slug } = useParams();
  const [data, setData] = useState();

  const { loading, error } = useQuery(GET_SHOW_WITH_PRESS, {
    variables: { slug },
    onCompleted: (data) => {
      setData(data);
    },
    skip: generalInfo,
  });

  useQuery(GET_SETTINGS, {
    onCompleted: (data) => {
      setData(data.vbSetting.pressFiles);
    },
    skip: !generalInfo,
  });

  useEffect(() => {
    headline("");
  }, []);

  const getFileType = (mime) => {
    let type = mime.split("/")[0] || "application";
    let ext = mime.split("/")[1];
    return { type, ext };
  };

  const saveFile = (file, title, mime) => {
    let splitFile = file.split("/");
    let fileName = mime
      ? `${title}.${mime.split("/")[1]}`
      : splitFile[splitFile.length - 1];

    const type = getFileType(mime);

    fetch(file, {
      headers: { "X-My-Custom-Header": "some value" },
      method: "GET",
      mode: "no-cors",
    })
      .then((response) =>
        type === "audio" ? response.arrayBuffer : response.blob()
      )
      .then((result) => {
        saveAs(result, fileName);
      });
  };

  if (error) {
    return <Redirect to={`/${locale}/404`} />;
  }

  if (loading) {
    return <Loader />;
  }

  const PhotoLinks = ({ files }) => {
    return (
      <section className="photoLinks">
        {files &&
          files.map((item) => (
            <div className="fileDetails">
              {getFileType(item.mimeType).type === "image" ? (
                <div className="imageContainer">
                  <div className="imageWrapper">
                    <img
                      src={item.sourceUrl}
                      alt={parse(
                        locale === "en" && item.mediaTexts.altTextEn
                          ? item.mediaTexts.altTextEn
                          : item.altText
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div className="fileBox">
                  {item.mimeType && (
                    <span>
                      <FormattedMessage
                        id={`press.file.${getFileType(item.mimeType).type}`}
                        defaultMessage={getFileType(item.mimeType).type}
                      />{" "}
                      (.{parse(getFileType(item.mimeType).ext)})
                    </span>
                  )}
                </div>
              )}
              <div className="fileInfo">
                <div>
                  {item.description && (
                    <h3>
                      {parse(
                        locale === "en" && item.mediaTexts.descriptionEn
                          ? item.mediaTexts.descriptionEn
                          : item.description
                      )}
                    </h3>
                  )}
                  {item.mediaTexts.copyright && (
                    <span>&copy; {parse(item.mediaTexts.copyright)}</span>
                  )}
                </div>
                {getFileType(item.mimeType).type === "image" ? (
                  <Button
                    as="div"
                    className="download"
                    onClick={() =>
                      saveFile(item.mediaItemUrl, item.slug, item.mimeType)
                    }
                  >
                    &gt; Download
                  </Button>
                ) : (
                  <a href={item.mediaItemUrl} download className="download">
                    &gt; Download
                  </a>
                )}
              </div>
            </div>
          ))}
      </section>
    );
  };

  return data ? (
    <div className="PressSection">
      {data.show ? (
        <ProductionTitle
          title={data.show.title}
          subtitle={data.show.productionDetails.subtitle}
          authors={data.show.productionCast.productionAuthors}
          // venueId={data.show.productionInfo.venue}
        />
      ) : (
        <div className="pageHeader">
          <h1>
            <FormattedMessage
              id="press.volksbuehne"
              defaultMessage="Pressematerial Volksbühne"
            />
          </h1>
        </div>
      )}
      <div className="topLinks">
        <FormattedMessage
          id="press.list"
          defaultMessage="Materialien zum Download:"
        />
        <Link to={`/${locale}/${formatMessage({ id: "route.press" })}`}>
          <span>&gt;&nbsp;</span>
          <FormattedMessage
            id="press.back"
            defaultMessage="zurück zur Übersicht"
          />
        </Link>
      </div>
      {data.show && <PhotoLinks files={data.show.pressFiles.pressPhotos} />}
      {data.pressPhotos && <PhotoLinks files={data.pressPhotos} />}
    </div>
  ) : null;
};

export default PressSection;

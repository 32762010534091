import React, { useCallback, useEffect, useState } from "react";
import "./IntroAnimation.scss";
import Fantomas from "../../../assets/fantomas.png";
import { animationActive } from "../../../cache";
import { Button } from "reakit";
import { useIntl } from "react-intl";

const IntroAnimation = () => {
  const [animActive, setAnimActive] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setTimeout(() => {
      setAnimActive(true);
    }, 10);
  }, []);

  const endAnimation = useCallback(() => {
    setAnimActive(false);
    setTimeout(() => {
      animationActive(false);
    }, 7000);
  }, [setAnimActive]);

  return (
    <Button
      as="div"
      aria-label={formatMessage({
        id: "aria.close-animation",
        defaultMessage: "Animation beenden",
      })}
      onClick={endAnimation}
      className={`AnimationContainer ${animActive ? "active" : "stopped"}`}
    >
      <img src={Fantomas} alt="Fantomas Animation" />
    </Button>
  );
};

export default IntroAnimation;

import { InMemoryCache, makeVar } from "@apollo/client";

export const cache = new InMemoryCache({
  typePolicies: {
    Show: {
      fields: {
        productionInfo: {
          merge: true,
        },
        productionDetails: {
          merge: true,
        },
      },
    },
    VbSetting: {
      fields: {
        vb_custom_settings: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        // event: {
        //   read(_, { args, toReference }) {
        //     return toReference({
        //       __typename: "Event",
        //       id: args.activityId,
        //     });
        //   },
        // },
        intlSlugs: {
          read() {
            return intlSlugs();
          },
        },
        cookiesAccepted: {
          read() {
            // The read function
            return localStorage.getItem("ACCEPTED_COOKIES");
          },
        },
        activeMenu: {
          read() {
            return activeMenu();
          },
        },
        headline: {
          read() {
            return headline();
          },
        },
        activeVenue: {
          read() {
            return activeVenue();
          },
        },
        selectedVenues: {
          read() {
            return selectedVenues();
          },
        },
        timeFrame: {
          read() {
            return timeFrame();
          },
        },
        loadedMonths: {
          read() {
            return loadedMonths();
          },
        },
        animationActive: {
          read() {
            return animationActive();
          },
        },
        hidePage1: {
          read() {
            return hidePage1();
          },
        },
        videoUrl: {
          read() {
            return videoUrl();
          },
        },
      },
    },
  },
});

export const intlSlugs = makeVar({ de: "", en: "" });
export const activeMenu = makeVar("");
export const headline = makeVar("");
export const activeVenue = makeVar("");
export const selectedVenues = makeVar([0, 0, 0]);
export const timeFrame = makeVar({
  start: { year: "", month: "" },
  end: { year: "", month: "" },
});
export const loadedMonths = makeVar([]);
export const animationActive = makeVar(false);
export const hidePage1 = makeVar(false);
export const videoUrl = makeVar("");

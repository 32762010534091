import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import "./MediaCredits.scss";

const MediaCredits = ({ galleryImages, videoData, vidPosition }) => {
  const { locale } = useIntl();

  const credits = useMemo(() => {
    let imgEn = [];
    let imgDe = [];
    let videoDe = [];
    let videoEn = [];

    if (videoData) {
      for (let prop in videoData) {
        if (Object.prototype.hasOwnProperty.call(videoData, prop)) {
          if (videoData[prop].videoUrl) {
            const copy = videoData[prop].videoCopyright
              ? ` © ${videoData[prop].videoCopyright}`
              : "";

            const de = `${
              videoData[prop].videoDescription || ""
            }${copy}`.trim();

            const en = `${
              videoData[prop].descriptionEn ||
              videoData[prop].videoDescription ||
              ""
            }${copy}`.trim();

            if (en) videoEn.push(en.replace(/(<([^>]+)>)|(\r\n|\n|\r)/gi, ""));
            if (de) videoDe.push(de.replace(/(<([^>]+)>)|(\r\n|\n|\r)/gi, ""));
          }
        }
      }
    }

    if (galleryImages) {
      for (let [, value] of Object.entries(galleryImages)) {
        const copy = value.mediaTexts.copyright
          ? ` © ${value.mediaTexts.copyright}`
          : "";
        const de = `${value.description || ""}${copy}`;
        const en = `${
          value.mediaTexts.descriptionEn || value.description || ""
        }${copy}`;

        if (en) imgEn.push(en.replace(/(<([^>]+)>)|(\r\n|\n|\r)/gi, ""));
        if (de) imgDe.push(de.replace(/(<([^>]+)>)|(\r\n|\n|\r)/gi, ""));
      }
    }

    let dataEn =
      vidPosition && vidPosition === "start"
        ? [...videoEn, ...imgEn]
        : [...imgEn, ...videoEn];

    let dataDe =
      vidPosition && vidPosition === "start"
        ? [...videoDe, ...imgDe]
        : [...imgDe, ...videoDe];

    const setEn = new Set(dataEn);
    const setDe = new Set(dataDe);

    if (setEn.size === 1) {
      dataEn.length = 1;
    }

    if (setDe.size === 1) {
      dataDe.length = 1;
    }

    return { en: dataEn, de: dataDe };
  }, [galleryImages, videoData, vidPosition]);

  const Credits = ({ data }) => {
    return data.map((item) => (
      <span className="credit" key={item}>
        <span className="separator"> &bull; </span>
        <span>{parse(item)}</span>
      </span>
    ));
  };

  return credits && (credits.de.length > 0 || credits.en.length > 0) ? (
    <div className="MediaCredits">
      <FormattedMessage
        id="mediaCredits.headline"
        defaultMessage="Photo/Video:"
      />{" "}
      {credits.en && locale === "en" ? (
        <Credits data={credits.en} />
      ) : (
        <Credits data={credits.de} />
      )}
    </div>
  ) : null;
};

export default MediaCredits;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import "./PageHeader.scss";
import { headline } from "../../cache";
import LepoNav from "../lepoNav/LepoNav";
import parse from "html-react-parser";
import {
  GET_BANNERS,
  GET_EVENTS_OF_VENUE,
  GET_HEADLINE,
  GET_SHOW_BY_ID,
} from "../../queries";
import { useResizer } from "../../customHooks";
import moment from "moment";
import {
  todayDateString,
  eventCancelledThea,
  filterActiveShows,
  sortEventsByDate,
} from "../../utilities";
import Loader from "../atoms/loader/Loader";

export const WithHeadline = ({ title, children }) => {
  const { formatMessage } = useIntl();

  headline(title);

  return (
    <>
      <Helmet>
        <meta title={formatMessage({ id: `menu.${title}` })} />
      </Helmet>
      {children}
    </>
  );
};

const PageHeader = ({ setHeaderHeight }) => {
  const [bannerSrc, setBannerSrc] = useState();
  const [backupBannerSrc, setBackupBannerSrc] = useState();
  const [bannerLinks, setBannerLinks] = useState();
  const [backupBannerLinks, setBackupBannerLinks] = useState();

  const [selectedEventShowId, setSelectedEventShowId] = useState();
  const { locale } = useIntl();

  const { formatMessage } = useIntl();
  const isMobile = useResizer();

  const { data } = useQuery(GET_HEADLINE);

  const { loading: loadingBanners } = useQuery(GET_BANNERS, {
    onCompleted: (banners) => {
      const settings = banners.vbSetting.vb_custom_settings;
      const daily = settings.dailyBanner;
      const backup = settings.backupBanner;
      const current = settings.currentBanner;
      const defaultBanner = current || daily;

      if (
        settings.dailyBannerShow &&
        settings.dailyBannerShow.productionBanner.banner
      ) {
        const linkBase =
          settings.dailyBannerShow.slug === "prater-studios"
            ? settings.dailyBannerShow.slug
            : `${formatMessage({
                id: `route.repertoire`,
              })}/${settings.dailyBannerShow.slug}`;

        const links = { de: `#/de/${linkBase}`, en: `#/en/${linkBase}` };
        setBannerSrc(settings.dailyBannerShow.productionBanner.banner);
        setBannerLinks(links);
      } else if (defaultBanner) {
        const links = {
          de: defaultBanner.mediaTexts.mediaLink,
          en: defaultBanner.mediaTexts.mediaLinkEn,
        };
        setBannerLinks(links);
        setBannerSrc(defaultBanner);
      } else if (
        settings.dailyBanners &&
        settings.dailyBanners.bannerSelectionStart &&
        moment(settings.dailyBanners.bannerSelectionStart).isSameOrAfter(
          moment()
        )
      ) {
        const startDay = moment(settings.dailyBanners.bannerSelectionStart);
        const dayIndex = moment().diff(startDay, "days");

        const todaysShow =
          settings.dailyBanners.bannerOrder[`day${dayIndex + 1}`];

        if (todaysShow && todaysShow.productionBanner.banner) {
          const routeBase =
            todaysShow.__typename === "SingleEvent"
              ? "route.events"
              : "route.repertoire";

          const linkBase = `${formatMessage({
            id: routeBase,
          })}/${todaysShow.slug}`;

          const links = { de: `#/de/${linkBase}`, en: `#/en/${linkBase}` };
          const src = todaysShow.productionBanner.banner;
          setBannerSrc(src);
          setBannerLinks(links);
        }
      } else if (backup) {
        const links = {
          de: backup.mediaTexts.mediaLink,
          en: backup.mediaTexts.mediaLinkEn,
        };
        setBackupBannerLinks(links);
        setBackupBannerSrc(backup);
      }
    },
  });

  const { loading: loadingEvents } = useQuery(GET_EVENTS_OF_VENUE, {
    variables: {
      venueId: "117,117",
      start: todayDateString,
      end: moment().add(7, "days").format("yyyyMMDD"),
      first: 100,
    },
    onCompleted: (data) => {
      const filteredNodes = data.events.nodes.filter(
        (x) => !eventCancelledThea(x) && x.eventDetails.seriesPart !== true
      );

      if (filteredNodes.length) {
        const sortedEvents = filteredNodes.sort(sortEventsByDate);
        setSelectedEventShowId(
          String(sortedEvents[0].eventDetails.productionId)
        );
      }
    },
    skip: !!bannerSrc,
    onError: (error) => {
      console.log(error);
    },
  });

  const { loading: loadingShow } = useQuery(GET_SHOW_BY_ID, {
    variables: { id: selectedEventShowId },
    skip: !selectedEventShowId || !!bannerSrc,
    onCompleted: (data) => {
      let activeShows = filterActiveShows(data.shows.nodes);

      if (
        activeShows &&
        activeShows[0] &&
        activeShows[0].productionBanner &&
        activeShows[0].productionBanner.banner
      ) {
        const linkBase = `${formatMessage({
          id: `route.repertoire`,
        })}/${activeShows[0].slug}`;

        const links = { de: `#/de/${linkBase}`, en: `#/en/${linkBase}` };
        setBannerSrc(activeShows[0].productionBanner.banner);
        setBannerLinks(links);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const renderBanner = (src, links) => {
    const banner = (
      <div className="banner">
        <img
          className="headerImg"
          src={src.mediaItemUrl}
          alt={
            src.mediaTexts.altTextEn || src.altText
              ? parse(
                  locale === "en" && src.mediaTexts.altTextEn
                    ? src.mediaTexts.altTextEn
                    : src.altText
                )
              : formatMessage({ id: "alt.headerImg", defaultMessage: "Banner" })
          }
        ></img>
      </div>
    );

    return links ? (
      <a href={locale === "en" ? links.en : links.de}>{banner}</a>
    ) : (
      banner
    );
  };

  // const [imgLoaded, setImgLoaded] = useState();
  // const onRefChange = useCallback(
  //   (node) => {
  //     if (node === null) {
  //       // DOM node referenced by ref has been unmounted
  //     } else if (imgLoaded) {
  //       const { offsetHeight } = node;
  //       setHeaderHeight(offsetHeight);
  //       // DOM node referenced by ref has changed and exists
  //     }
  //   },
  //   [imgLoaded]
  // );
  // adjust deps
  // setHeaderRef(ref ? ref : null);
  // const [stickyImg, setStickyImg] = useState(true);
  // const imgRef = useRef();
  // const [imgHeight, setImgHeight] = useState();

  // const imageLoaded = () => {
  //   if (imgRef.current) {
  //     const { offsetHeight } = imgRef.current;
  //     console.log(offsetHeight);
  //     setImgHeight(offsetHeight);
  //   }
  // };
  // const location = useLocation();

  // useEffect(() => {
  //   const parts = location.pathname.split("/");
  //   const key = getRouteKeys(locale, parts[parts.length - 1])["en"];

  //   console.log(locale, parts[parts.length - 1], key);
  //   // return key;
  //   // if(parts.includes('spielplan') || parts.includes('programme')
  // }, [locale, location]);
  if (data.headline && data.headline === "*programme") {
    return (
      <div
        className="pageHeader lepo"
        // ref={onRefChange}
        // style={stickyImg ? { top: `${imgHeight}px` } : {}}
      >
        {loadingBanners || loadingEvents || loadingShow ? (
          <Loader />
        ) : (
          <>
            {(bannerSrc || backupBannerSrc) &&
              !isMobile &&
              renderBanner(
                bannerSrc ? bannerSrc : backupBannerSrc,
                bannerSrc ? bannerLinks : backupBannerLinks
              )}
          </>
        )}
        <LepoNav />
      </div>
    );
  }

  return data.headline && !data.headline.includes("*") ? (
    <>
      {/* {settings && (
        <div className="imgContainer">
          <img
            className="headerImg"
            src={
              settings.vbSetting.vb_custom_settings.grafikStartseite
                .mediaItemUrl
            }
            alt={formatMessage({ id: "alt.headerImg" })}
          ></img>
        </div>
      )} */}
      <div className="pageHeader">
        <h1>{parse(formatMessage({ id: `menu.${data.headline}` }))}</h1>
        {/* {pageTabs[category] &&
        pageTabs[category].map((link) => (
          <NavLink
            to={
              link === "repertoire"
                ? path
                : formatMessage({ id: `route.${link}` })
            }
            activeClassName="active"
          >
            <h2>
              {" "}
              <FormattedMessage id={`menu.${link}`} defaultMessage={link} />
            </h2>
            <span className="dot">&bull;</span>
          </NavLink>
        ))} */}
      </div>
    </>
  ) : null;
};

PageHeader.propTypes = {
  category: PropTypes.string,
};

export default PageHeader;

import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Button } from "reakit";
import { useOutsideAlerter } from "../../customHooks";
import { GET_SETTINGS } from "../../queries";
import "./MonthSelector.scss";

const getMonthsBeforeNow = (start) => {
  let currentMonth = start.startOf("month");
  const endBefore = moment().startOf("month");
  let months = [];

  while (currentMonth.isSameOrBefore(endBefore)) {
    months.push(currentMonth.clone());
    currentMonth.add(1, "month");
  }

  return months;
};

const MonthSelector = ({ mobile, toggleOpen, openedMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allMonths, setAllMonths] = useState(false);
  const { locale, formatMessage } = useIntl();
  const monthsRef = useRef();

  useOutsideAlerter(monthsRef, mobile ? () => {} : () => setIsOpen(false));

  useQuery(GET_SETTINGS, {
    onCompleted: (data) => {
      const monthsBefore = getMonthsBeforeNow(
        moment(data.vbSetting.vb_custom_settings.startmonth)
      );
      setAllMonths(monthsBefore);
    },
  });

  useEffect(() => {
    if (openedMenu === "search") setIsOpen(false);
  }, [openedMenu]);

  useEffect(() => {
    if (toggleOpen && isOpen) toggleOpen("months");
  }, [isOpen, toggleOpen]);

  return (
    <div className="MonthSelector" ref={monthsRef}>
      <Button
        id="what-happened"
        // as="a"
        onClick={() => setIsOpen(!isOpen)}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     setIsOpen(!isOpen);
        //   }
        // }}
        className={isOpen ? "active" : ""}
        tabIndex="0"
        aria-expanded={isOpen}
      >
        <FormattedMessage
          id="menu.what-happened"
          defaultMessage="Was bisher geschah"
        />
      </Button>
      {isOpen && openedMenu !== "search" && (
        <div className="dropDown">
          <div
            className="innerDropDown"
            role="listbox"
            aria-labelledby="what-happened"
          >
            {allMonths &&
              allMonths.map((month) => (
                <div key={`${month.format("YYYY")}-${month.format("MM")}`}>
                  <NavLink
                    role="option"
                    to={`/${locale}/${formatMessage({
                      id: "route.programme",
                    })}/${month.format("YYYY")}/${month.format("MM")}`}
                  >
                    <span aria-hidden>&bull;&nbsp;</span>
                    {month.locale(locale).format("MMMM YYYY")}
                  </NavLink>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthSelector;

import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router";
import CategoryOverview from "../../components/categoryOverview/CategoryOverview";
import { IntlContext } from "../IntlWrapper/IntlWrapper";
import { getRouteKeys } from "../route-utils";
import Programme from "../../pages/Programme";
import Show from "../../components/show/Show";
import SingleEvent from "../../components/singleEvent/SingleEvent";
import Page from "../../components/page/Page";
import People from "../../components/people/People";
import Newsletter from "../../components/newsletter/Newsletter";
import { WithHeadline } from "../../components/pageHeader/PageHeader";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { getCookieConsentValue } from "react-cookie-consent";
import VenuePage from "../../components/venuePage/VenuePage";
import NotFound from "../../components/atoms/NotFound/NotFound";
import PressSection from "../../components/pressSection/PressSection";
import LepoWrapper from "../../components/leporello/LepoWrapper";

export const getLangFromURL = (url) => {
  let routeParts = url.split("/");
  return routeParts[1];
};

const LanguageRoutes = ({
  lang,
  scrollableDiv,
  startpage,
  season,
  hidePage1,
}) => {
  const { setLocale } = useContext(IntlContext);
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { trackPageView } = useMatomo();
  const cookieConsent = getCookieConsentValue();
  // const [mostRecentSeason, setMostRecentSeason] = useState();

  // useQuery(GET_SPIELZEITEN, {
  //   onCompleted: (seasons) => {
  //     let highest = "";
  //     for (let season of seasons.spielzeiten.nodes) {
  //       highest = season.title > highest ? season : highest;
  //     }
  //     setMostRecentSeason(highest);
  //   },
  // });

  // set locale based on path
  useEffect(() => {
    if (path.includes(lang)) {
      setLocale(lang);
    }
  }, [path]);

  //MATOMO TRACKING
  useEffect(() => {
    if (cookieConsent) {
      trackPageView();
    }
  }, [pathname, cookieConsent, trackPageView]);

  useEffect(() => {
    if (
      scrollableDiv &&
      (!pathname.includes("72c32kzpzv") || pathname.endsWith("72c32kzpzv")) &&
      (!pathname.includes("crew") || pathname.endsWith("crew"))
    ) {
      scrollableDiv.current.scrollTo({
        top: 0,
        left: 0,
        // behavior: "smooth",
      });
    }
  }, [pathname, scrollableDiv]);

  return (
    <Switch>
      <Route exact path={`/${lang}`}>
        {startpage === "page-1" && !hidePage1 ? (
          <Redirect to={`/${lang}/${getRouteKeys("en", "page-1")[lang]}`} />
        ) : (
          <Redirect to={`/${lang}/${getRouteKeys("en", "programme")[lang]}`} />
        )}
      </Route>
      {/* Don't use exact below if you want nested routes to match */}
      <Route
        path={`${path}/${
          getRouteKeys("en", "programme")[lang]
        }/:paramYear/:paramMonth`}
      >
        <WithHeadline title="*programme">
          {/* <Programme scrollElem={scrollableDiv} /> */}
          <LepoWrapper scrollElem={scrollableDiv} />
        </WithHeadline>
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "programme")[lang]}`}>
        <WithHeadline title="*programme">
          <Programme scrollElem={scrollableDiv} />
        </WithHeadline>
      </Route>

      <Route path={`${path}/${getRouteKeys("en", "contact")[lang]}`}>
        <Page title="contact" />
      </Route>
      <Route exact path={`${path}/${getRouteKeys("en", "press")[lang]}`}>
        <WithHeadline title="press">
          <CategoryOverview
            key="category-press"
            type="shows"
            withPress={true}
          />
        </WithHeadline>
      </Route>
      <Route
        exact
        path={`${path}/${getRouteKeys("en", "press")[lang]}/volksbuehne`}
      >
        <PressSection generalInfo />
      </Route>
      <Route exact path={`${path}/${getRouteKeys("en", "press")[lang]}/:slug`}>
        <PressSection />
      </Route>

      {/* Test route for crew */}
      {/* <Route path={`${path}/72c32kzpzv`}>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <WithHeadline title="crew">
          <People />
        </WithHeadline>
      </Route> */}

      <Route path={`${path}/${getRouteKeys("en", "crew")[lang]}`}>
        <WithHeadline title="crew">
          <People />
        </WithHeadline>
      </Route>

      <Route exact path={`${path}/${getRouteKeys("en", "repertoire")[lang]}`}>
        <WithHeadline title="repertoire">
          <CategoryOverview
            key="category-repertoire"
            type="shows"
            selected="repertoire"
          />
        </WithHeadline>
      </Route>

      <Route exact path={`${path}/${getRouteKeys("en", "premieres")[lang]}`}>
        <WithHeadline title="premieres">
          <CategoryOverview
            key="category-premieres"
            type="shows"
            selected="premieres"
          />
        </WithHeadline>
      </Route>

      <Route exact path={`${path}/${getRouteKeys("en", "series")[lang]}`}>
        <WithHeadline title="series">
          <CategoryOverview
            key="category-series"
            type="shows"
            selected="series"
          />
        </WithHeadline>
      </Route>
      <Route exact path={`${path}/${getRouteKeys("en", "series")[lang]}/:slug`}>
        <Show showArchive />
      </Route>
      <Route
        exact
        path={`${path}/${getRouteKeys("en", "repertoire")[lang]}/:slug`}
      >
        <Show />
      </Route>
      <Route exact path={`${path}/${getRouteKeys("en", "archive")[lang]}`}>
        <WithHeadline title="archive">
          <CategoryOverview key="category-archive" type="shows" archive />
        </WithHeadline>
      </Route>
      {/* <Route exact path={`${path}/${getRouteKeys("en", "archive")[lang]}`}>
        <Redirect
          to={`/${lang}/${getRouteKeys("en", "archive-1")[lang]}/${
            mostRecentSeason.slug
          }`}
        />
      </Route> */}
      <Route
        exact
        path={`${path}/${getRouteKeys("en", "archive")[lang]}/:year`}
      >
        <WithHeadline title="archive">
          <CategoryOverview key="category-archive" type="shows" archive />
        </WithHeadline>
      </Route>
      <Route exact path={`${path}/${getRouteKeys("en", "events")[lang]}/:slug`}>
        {/* <Show /> */}
        <SingleEvent />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "tickets")[lang]}`}>
        <Page title="tickets" />
      </Route>
      {/* always add a venueId to map the color (117 = standard blue) */}
      <Route exact path={`${path}/${getRouteKeys("en", "p14")[lang]}`}>
        <VenuePage
          title="p14"
          venueId={148}
          search="P14"
          eventCalendarType="bySearch"
          showPremieres
        />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "p14")[lang]}/:slug`}>
        <Show
          linkBack={`${path}/${getRouteKeys("en", "p14")[lang]}`}
          linkBackKey={"p14"}
        />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "sternschuppen")[lang]}`}>
        <VenuePage
          title="sternschuppen"
          venueId="168"
          eventCalendarType="byVenue"
        />
      </Route>
      <Route
        exact
        path={`${path}/${getRouteKeys("en", "gruener-salon")[lang]}`}
      >
        <VenuePage
          title="grüner salon"
          venueId={147}
          eventCalendarType="byVenue"
        />
      </Route>
      <Route
        exact
        path={`${path}/gruener-salon/${
          getRouteKeys("en", "series")[lang]
        }/:slug`}
      >
        <Show showArchive />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "prater-studios")[lang]}`}>
        <VenuePage
          title="prater studios"
          venueIds="195,196,200"
          eventCalendarType="byVenue"
        />
      </Route>

      <Route path={`${path}/${getRouteKeys("en", "music")[lang]}`}>
        <VenuePage
          title="musik"
          venueId={117}
          // genreId="44"
          sortInto="music"
          eventCalendarType="byGenre"
        />
      </Route>

      <Route exact path={`${path}/${getRouteKeys("en", "festiwalla")[lang]}`}>
        <VenuePage
          title="festiwalla"
          venueId="festival"
          startDate="20240710"
          endDate="20240714"
          eventCalendarType="byDate"
        />
      </Route>

      {/* <Route path={`${path}/${getRouteKeys("en", "cinema")[lang]}`}>
        <VenuePage
          title="kino"
          venueId={117}
          // genreId="37"
          sortInto="cinema"
          // search="kino"
          eventCalendarType="byGenre"
          showArchive
          omitVenueInArchive={"191"}
        />
      </Route> */}
      {/* 
      <Route path={`${path}/${getRouteKeys("en", "kino-truck")[lang]}`}>
        <Page title="kino-truck" />
      </Route>
      {/* FOOTER routes */}
      <Route path={`${path}/${getRouteKeys("en", "terms-conditions")[lang]}`}>
        <Page title="terms-conditions" />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "imprint")[lang]}`}>
        <Page title="imprint" />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "privacy-policy")[lang]}`}>
        <Page title="privacy-policy" />
      </Route>

      <Route path={`${path}/ukraine`}>
        <Page title="ukraine" />
      </Route>

      <Route path={`${path}/${getRouteKeys("en", "newsletter")[lang]}`}>
        <WithHeadline title="newsletter">
          <Newsletter />
        </WithHeadline>
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "news")[lang]}`}>
        <Page title="news" />
      </Route>
      {!hidePage1 && (
        <Route path={`${path}/${getRouteKeys("en", "page-1")[lang]}`}>
          <Page title="page-1" type="largeTitle" noTitle />
        </Route>
      )}
      <Route path={`${path}/${getRouteKeys("en", "audiodescription")[lang]}`}>
        <Page title="audiodescription" />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "accessibility")[lang]}`}>
        <Page title="accessibility" />
      </Route>
      <Route
        path={`${path}/${
          getRouteKeys("en", "accessibility-declaration")[lang]
        }`}
      >
        <Page title="accessibility-declaration" />
      </Route>
      <Route path={`${path}/${getRouteKeys("en", "jobs")[lang]}`}>
        <Page title="jobs" />
      </Route>
      {/* 404 */}
      <Route path={`${path}/404`}>
        <WithHeadline title="404">
          <NotFound />
        </WithHeadline>
      </Route>
      <Route path="*">
        <Redirect to={`/${lang}/404`} />
      </Route>
    </Switch>
  );
};

export default LanguageRoutes;
